import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {path:'home', component:HomeComponent},
  {path:'about-us', loadChildren:()=> import('./features.module')
    .then(mod =>mod.FeaturesModule)
  },
  {path:'contact-us', loadChildren:()=> import('./contact-us/contact-us.module')
    .then(mod =>mod.ContactUsModule)
  },
  {path:'dashboard', loadChildren:()=> import('./dashboard/dashboard.module')
    .then(mod =>mod.DashboardModule)
  },
  {path:'products', loadChildren:()=> import('./products.module')
    .then(mod =>mod.ProductsModule)
  },
  {path:'account', loadChildren:()=> import('./account/account.module')
    .then(mod =>mod.AccountModule)
  },
  {path:'', redirectTo:'/home', pathMatch:'full'},
  {path:'**', component:PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
