import { Component } from '@angular/core';
import {MediaObserver, MediaChange} from '@angular/flex-layout';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { Subscription } from 'rxjs/internal/Subscription';
import { Title, Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  loggedIn:boolean;
  user:SocialUser;

  watcher:Subscription;
  deviceXs:boolean;

  constructor(private mediaObserver:MediaObserver,
    private authService: SocialAuthService,
    private titleService:Title,
    private metaService:Meta
   ) {}
  ngOnInit() {
    this.titleService.setTitle('Saidika Loans - Logbook loans, Salary checkoff loans at affordable interest rates.')
    this.metaService.addTags([
      {name:'description', content:'We provide credit to individuals, SMEs and employees of institutions excluded from the mainstream financial institutions.'},
      {name:'keywords',content:'logbook loans,salary checkoff loans,low interest rates'}
    ])
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
    this.watcher = this.mediaObserver.asObservable().subscribe((result:MediaChange[])=>{
      this.deviceXs = result[0].mqAlias === 'xs' ? true : result[0].mqAlias ==='sm' ? true : false;
    });
  }
  ngOnDestroy(){
    this.watcher.unsubscribe();
  }
  customOptions: any = {
    loop: true,
    margin: 10,
    autoplay:true,
    responsiveClass: true,
    center: true,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
       items: 1
     },
      480: {
       items: 1
     },
      940: {
       items: 1
     }
    },
   nav: false
  }
}
