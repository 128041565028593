<div class="footer" fxLayout="column" fxLayoutAlign="space-around none">
    
    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign="space-around none">
        <div>
            <ul>
                <li><h2>Contact Us</h2></li>
                <li><span><mat-icon class="orange600"> phone_in_talk</mat-icon></span>+254725 282 195</li>
                <li><span><mat-icon class="orange600"> phone_in_talk</mat-icon></span>+254721 179 449</li>
                <li><span><mat-icon class="orange600"> phone_in_talk</mat-icon></span>+254737 632 895</li>
                <li><span><mat-icon class="orange600">mail</mat-icon></span>info@saidikacredit.co.ke</li>
                <li><span><mat-icon class="orange600">home</mat-icon></span>Lyric House, 7<sup>th</sup> Floor, Kimathi Street</li>
            </ul>
            
        </div>
        <mat-divider *ngIf="deviceXs"></mat-divider>
        <div  class="subNewsletter">
            <h2>Subscribe to our newsletter</h2>
            <form [formGroup]="formGroup" class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" placeholder="e.g abc@gmail.com">
                    <mat-error style="color:red" *ngIf="email.invalid && email.touched">
                      Your Email does not look right.
                  </mat-error>
                </mat-form-field>
                <button mat-flat-button >Subscribe</button>
            </form>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="copyright" fxLayout="row" fxLayoutAlign="center none">
        <h3>Saidika Credit Limited&nbsp; &copy;2023</h3>
    </div>
</div>