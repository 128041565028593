<owl-carousel-o [options]="customOptions">
    <div  *ngFor="let slide of homepageSlider">
        <ng-container>
            <ng-template class="slide" carouselSlide >
                <div class="home-page-products" >
                    <div *ngIf="slide==1"class="home-welcome">
                        <h1 *ngIf="!deviceXs" style="color:#66ff00; font-size:50px;"> Welcome to Saidika Credit Limited.</h1>
                        <h1 *ngIf="deviceXs" style="color:#66ff00; font-size:25px;"> Welcome to Saidika Credit Limited.</h1>
                        <h1 style="color:whitesmoke">We thank you for giving us an opportunity to serve you.</h1>
                        <div class="products" fxLayout.xs="column" fxLayout="row" fxLayoutAlign="space-evenly center">
                            <div class="logbook" style="border: 1px solid #66ff00;">
                                <a routerLink="/products/logbook-loan">
                                    <!-- <img  src="assets/images/logbook-img.png" alt="logbook loan with low interest rates image"> -->
                                    <mat-icon style="font-size: 100px;">directions_car</mat-icon>
                                    <!-- <i class="fas fa-car" style="font-size: 100px; color: #66ff00"></i> -->
                                    <h1>Logbook Loans</h1>
                                </a>
                            </div>
                            <div class="salaryCheckoff" style="border: 1px solid #66ff00;">
                                <a routerLink="/products/car-finance">
                                    <!-- <img  src="assets/images/carf.png" alt="car finance loans"> -->
                                    <!-- <mat-icon style="font-size: 100px;">vehicle</mat-icon> -->
                                    <!-- <i class="fas fa-car" style="font-size: 100px; color: #66ff00"></i> -->
                                    
                                    <i class="fas fa-car-side" style="font-size: 100px; color: #66ff00"></i>
                                    <h1>Car Finance</h1>
                                </a>
                            </div>
                            <div class="salaryCheckoff" style="border: 1px solid #66ff00;">
                                <a routerLink="/products/salary-checkoff">
                                    <img  src="assets/images/employee-checkoff.png" alt="salary checkoff loan with low interest rates image">
                                    <h1>Salary Checkoff Loans</h1>
                                </a>
                            </div>
                            <div class="salaryCheckoff" style="border: 1px solid #66ff00;">
                                <a routerLink="/products/insurance-agency">
                                    <!-- <img  src="assets/images/employee-checkoff.png" alt="salary checkoff loan with low interest rates image"> -->
                                    <mat-icon style="font-size: 100px;">beach_access</mat-icon>
                                    <h1>Insurance Agency</h1>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="slide==2"class="logbook-product">
                        <mat-card >
                            <mat-card-header>
                               <mat-card-title>Logbook loans</mat-card-title>
                               <mat-card-subtitle></mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <p>We give log book loans to individuals and ...</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a routerLink="/products/logbook-loan">
                                    <button mat-raised-button color="primary">Learn More...</button>
                                </a>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                    <div *ngIf="slide==3"class="car-finance">
                        <mat-card>
                            <mat-card-header>
                               <mat-card-title>Car Finance</mat-card-title>
                               <mat-card-subtitle></mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <p>we give car finance loans</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a routerLink="/products/car-finance">
                                    <button mat-raised-button color="primary">Learn More...</button>
                                </a>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                    <div *ngIf="slide==4"class="checkoff">
                        <mat-card>
                            <mat-card-header>
                               <mat-card-title>Salary Checkoff Loans</mat-card-title>
                               <mat-card-subtitle></mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <p>We give Check-off loans to employees from...</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a routerLink="/products/salary-checkoff">
                                    <button mat-raised-button color="primary">Learn More...</button>
                                </a>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                    <div *ngIf="slide==5"class="insurance-agency">
                        <mat-card>
                            <mat-card-header>
                               <mat-card-title>Insurance Agency</mat-card-title>
                               <mat-card-subtitle></mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <p>At Saidika Insurance Agency, we have partnered with great insurance partners to offer insurance solutions tailored to secure what matters to you most.</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a routerLink="/products/insurance-agency">
                                    <button mat-raised-button color="primary">Learn More...</button>
                                </a>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</owl-carousel-o>