<div *ngIf="deviceXs">
  <mat-grid-list  cols="2" rowHeight="100px" gutterSize="5px" >
    <div >
      <mat-grid-tile>
        <img src="assets/images/logo.png" style="width:300px;height:150px;"  alt="logo">
      </mat-grid-tile>
    </div>
  </mat-grid-list>
</div>
  <div *ngIf="!deviceXs">
    <mat-grid-list cols="4" rowHeight="150px" gutterSize="5px" >
      <mat-grid-tile>
        <img src="assets/images/logo.png" style="width:300px;height:200px;"  alt="saidika credit logo">
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-icon class="orange600"> add_location</mat-icon> 
        <span></span>
        <ul> 
          <li> <b>Our Location</b> </li>
          <li>Lyric House, 7<sup>th</sup> Floor,</li>
          <li>Kimathi Street</li>
        </ul>
      </mat-grid-tile>
      <mat-grid-tile>
        <span><mat-icon class="orange600">timer</mat-icon></span>
        <ul>
          <li> <b>Working Hours</b>  </li>
          <li>Mon-Fri 8:00 - 5:00PM</li>
          <li>Sat: 8:30 - 12:30PM</li>
          <li>Sunday & Public holidays closed</li>
        </ul>
      </mat-grid-tile>
      <mat-grid-tile>
        <span><mat-icon class="orange600"> phone_in_talk</mat-icon></span>
        <ul>
          <li><b>Office Lines</b></li>
          <li>+254725 282 195</li>
          <li>+254721 179 449</li>
          <li>+254737 632 895</li>
        </ul>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  

  <mat-toolbar>
    <mat-toolbar-row>
      <button mat-button routerLink="/home">
        <span><mat-icon style="font-size: large;color: #08356d;" >home</mat-icon></span>Home</button>
      
      <button mat-button [matMenuTriggerFor]="products">Our Products</button>
      <mat-menu #products="matMenu" xPosition="after">
        <button mat-menu-item routerLink="products/logbook-loan">Logbook Loans</button>
        <button mat-menu-item routerLink="products/car-finance">Car Finance</button>
        <button mat-menu-item routerLink="products/salary-checkoff">Salary Checkoff</button>
        <button mat-menu-item routerLink="products/insurance-agency">Insurance Agency</button>
      </mat-menu>
      <button mat-button routerLink="/about-us">About Us</button>
      <div *ngIf="!deviceXs">
        <button mat-button routerLink="/contact-us">Contact Us</button>
      </div>
      
      <span class="example-spacer"></span>
      
      <div *ngIf="loggedIn">
        <button mat-button routerLink="/dashboard">Dashboard</button>
        <button mat-button >{{user.email}}</button>
        <button mat-button [routerLink]="['/account/logout',{action:'logout'}]">Logout</button>
      </div>
      <div *ngIf="!loggedIn">
        <button routerLink="/account" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
          <mat-icon>account_circle</mat-icon>
        </button>
      </div>
      
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="deviceXs">
      <button mat-button routerLink="/contact-us">Contact Us</button>
      <button mat-flat-button >
        <span><mat-icon style="font-size: medium; color:#66ff00" class="orange600"> phone_in_talk</mat-icon></span>0725 282 195
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div>
    <router-outlet></router-outlet>
  </div>
  
 <!--  FOOTER STARTS HERE -->
 <app-footer></app-footer>
 