import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import {AbstractControl,FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  watcher:Subscription;
  deviceXs:boolean;
  breakpoint:number;
  formGroup:FormGroup;

  constructor(private mediaObserver:MediaObserver,private _formBuilder: FormBuilder,) {}
  ngOnInit() {
    this.watcher = this.mediaObserver.asObservable().subscribe((result:MediaChange[])=>{
      this.deviceXs = result[0].mqAlias === 'xs' ? true : false;
      if(this.deviceXs)
      {
          this.breakpoint=1;
      }else this.breakpoint=4;
    });
    this.formGroup = this._formBuilder.group({
      email: ['',[Validators.required,Validators.email]],
    });
  }
  ngOnDestroy(){
    this.watcher.unsubscribe();
  }
  subscribeToNewsLetter(){}
  get email(){
    return this.formGroup.get('email');
  }
}
