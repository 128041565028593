import { Component,VERSION } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import { LoanProducts } from '../LoanProducts';
import { Meta, Title } from '@angular/platform-browser';

export class TodaysDate{
  date:Date;
  greetings:string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent{
  ngVersion: string = VERSION.full;
  matVersion: string = '5.1.0';
  breakpoint: number;

  loanProducts:LoanProducts[]=[];


  watcher:Subscription;
  deviceXs:boolean;

  homepageSlider:string[]=['1','2','3','4','5'];
  constructor(private mediaObserver:MediaObserver,
    public dialog: MatDialog,
    private titleService:Title,
    private metaService:Meta) {}
  
  ngOnInit() {
    this.titleService.setTitle('Logbook loans at affordable interest rates.')
    this.metaService.addTags([
      {name:'description', content:'Use your logbook to secure upto Kes 2 million. We have flexible terms, low interest and processing within 6 hrs'},
      {name:'keywords',content:'logbook loans,salary checkoff loans,low interest rates'}
    ])

    this.watcher = this.mediaObserver.asObservable().subscribe((result:MediaChange[])=>{
      console.log(result[0].mqAlias);
      this.deviceXs = result[0].mqAlias === 'xs' ? true : false;
    });
    this.loanProducts =[
      {LoanType:'Logbook Loans',Message:'Our logbook loan interest rates go to as low as 1.5%',ProductUrl:"/products/logbook-loan"},
      {LoanType:'Salary Checkoff Loans',Message:'This is a facility desirable to all employees, learn more',ProductUrl:"/products/salary-checkoff"}
    ];
    console.log(this.loanProducts[0].LoanType,this.loanProducts[0].Message,this.loanProducts[0].ProductUrl);
  }
  ngOnDestroy(){
    this.watcher.unsubscribe();
  }
  onResize(event:any) {
  }
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return value;
  }
  customOptions: any = {
    loop: true,
    margin: 10,
    autoplay:true,
    responsiveClass: true,
    center: true,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
       items: 1
     },
      480: {
       items: 1
     },
      940: {
       items: 1
     }
    },
   nav: false
  }
}